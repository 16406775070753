import type { Claim } from '@/types/claim'
import type { ContractRequest, ContractRequestArchived } from '@/types/contractRequests'
import type { Contract, Transfer, UnPaidItem } from '@/types/contracts'
import type {
  DeleteDocumentData,
  Document,
  DocumentCreated,
  DocumentData,
  DrivingLicenceDocumentData,
  UserDocument
} from '@/types/documents'
import type {
  LifeProcedure,
  LifeProcedureData,
  LifeProcedureUpdated,
  TicketCreated,
  ZendeskTicketData
} from '@/types/general'
import type { Authenticated, Company, Me, MeToken, Profile } from '@/types/me'
import axios from 'axios'
import { AxiosError } from 'axios'

const SECURITY_TOKEN = import.meta.env.VITE_SECURITY_TOKEN
const BACKEND_URL = import.meta.env.VITE_RAILS_BACKEND_URL
const backendUrl = (path: string) => `${BACKEND_URL}/api/my/v1${path}`
const v2BackendUrl = (path: string) => `${BACKEND_URL}/api/v2${path}`

const getMe = async () =>
  await axios.request<{ data: Me }>({
    method: 'GET',
    url: backendUrl('/me'),
    withCredentials: true
  })

const getCompanyDetails = async (companyId: string) =>
  await axios.request<{ data: Company }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}`),
    withCredentials: true
  })

const getMeToken = async () =>
  await axios.request<{ data: MeToken }>({
    method: 'GET',
    url: backendUrl('/me/token'),
    withCredentials: true
  })

export const getUserProfiles = async () =>
  await axios.request<{ data: Profile[] }>({
    method: 'GET',
    url: backendUrl(`/me/profiles`),
    withCredentials: true
  })

const postOnboard = async () =>
  await axios.request<{ data: 'onboarded' }>({
    method: 'POST',
    url: backendUrl('/onboard'),
    withCredentials: true
  })

const getContractRequests = async () =>
  await axios.request<{ data: ContractRequest[] }>({
    method: 'GET',
    url: backendUrl('/contract_requests'),
    withCredentials: true
  })

const getContracts = async () =>
  await axios.request<{ data: Contract[] }>({
    method: 'GET',
    url: backendUrl('/contracts'),
    withCredentials: true
  })

const getCompanyContracts = async (companyId: string) =>
  await axios.request<{ data: Contract[] }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/contracts`),
    withCredentials: true
  })

const getCompanyContract = async (companyId: string, contractId: string) =>
  await axios.request<{ data: Contract }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/contracts/${contractId}`),
    withCredentials: true
  })

export const getTransfers = async () =>
  await axios.request<{ data: Transfer[] }>({
    method: 'GET',
    url: backendUrl(`/transfers`),
    withCredentials: true
  })

const getDocuments = async () =>
  await axios.request<{ data: UserDocument[] }>({
    method: 'GET',
    url: backendUrl('/documents?type=upload'),
    withCredentials: true
  })

const getInsuranceCertificates = async () =>
  await axios.request<{ data: Contract[] }>({
    method: 'GET',
    url: `${BACKEND_URL}/api/my/v1/insurance_certificates`,
    withCredentials: true
  })

const getB2BInsuranceCertificates = async (companyId: string) =>
  await axios.request<{ data: Contract[] }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/insurance_certificates`),
    withCredentials: true
  })

const getDocumentsToSign = async () =>
  await axios.request<{ data: Document[] }>({
    method: 'GET',
    url: backendUrl('/documents?type=sign'),
    withCredentials: true
  })

const getPendingDocumentsToSign = async () =>
  await axios.request<{ data: Document[] }>({
    method: 'GET',
    url: backendUrl('/documents?type=sign&status=pending'),
    withCredentials: true
  })

const getCompanyPendingDocumentsToSign = async (companyId: string) =>
  await axios.request<{ data: Document[] }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/documents?type=sign&status=pending`),
    withCredentials: true
  })

const getCompanyDocuments = async (companyId: string) =>
  await axios.request<{ data: Document[] }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/documents`),
    withCredentials: true
  })

const getPendingDocumentsToUpload = async () =>
  await axios.request<{ data: UserDocument[] }>({
    method: 'GET',
    url: backendUrl('/documents?type=upload&status=pending'),
    withCredentials: true
  })

const getPendingUnpaids = async () =>
  await axios.request<{ data: UnPaidItem[] }>({
    method: 'GET',
    url: backendUrl('/unpaids'),
    withCredentials: true
  })

export const visitPage = async (pageURL: String) =>
  await axios.request({
    method: 'POST',
    url: backendUrl(`/visit`),
    data: {
      url: pageURL
    },
    withCredentials: true
  })

export const getClaim = async (uuid: String) =>
  await axios.request<{ data: Claim }>({
    method: 'GET',
    url: backendUrl(`/claims/${uuid}`),
    withCredentials: true
  })

export const getClaims = async () =>
  await axios.request<{ data: Claim[] }>({
    method: 'GET',
    url: backendUrl(`/claims`),
    withCredentials: true
  })

export const getCompanyClaims = async (companyId: string) =>
  await axios.request<{ data: Claim[] }>({
    method: 'GET',
    url: backendUrl(`/companies/${companyId}/claims`),
    withCredentials: true
  })

export const getContract = async (uuid: String) =>
  await axios.request<{ data: Contract }>({
    method: 'GET',
    url: backendUrl(`/contracts/${uuid}`),
    withCredentials: true
  })

export const getContractRequest = async (uuid: String) =>
  await axios.request<{ data: ContractRequest }>({
    method: 'GET',
    url: backendUrl(`/contract_requests/${uuid}`),
    withCredentials: true
  })

export const getLifeProcedure = async (uuid: String) =>
  await axios.request<{ data: LifeProcedure }>({
    method: 'GET',
    url: backendUrl(`/life_procedures/${uuid}`),
    withCredentials: true
  })

export const patchLifeProcedure = async (body: LifeProcedureData): Promise<LifeProcedureUpdated> => {
  const { data } = await axios.request<LifeProcedureUpdated>({
    method: 'PATCH',
    url: backendUrl(`/life_procedures/${body.lifeProcedureUuid}`),
    data: { life_procedure: { payload: body.payload } },
    withCredentials: true
  })

  return data
}

export const changePassword = async (currentPassword: string, newPassword: string) => {
  const { data } = await axios.request<{ message: string }>({
    method: 'PATCH',
    url: backendUrl('/me/password'),
    data: {
      user: {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: newPassword
      }
    },
    withCredentials: true
  })

  return !!data.message
}

export const resetPassword = async (
  password: string,
  password_confirmation: string,
  reset_password_token: string
): Promise<any> => {
  const { data } = await axios.request<{ message: string }>({
    method: 'POST',
    url: backendUrl('/me/password'),
    data: {
      user: {
        password: password,
        password_confirmation: password_confirmation,
        reset_password_token: reset_password_token
      }
    },
    withCredentials: true
  })

  return data
}

export const postZendeskTicket = async (body: ZendeskTicketData): Promise<boolean> => {
  const { data } = await axios.request<TicketCreated>({
    method: 'POST',
    url: backendUrl(`/tickets`),
    data: body,
    withCredentials: true
  })
  return data.ticket_created
}

export const archiveContractRequest = async (contractUuid: string): Promise<boolean> => {
  const { data } = await axios.request<ContractRequestArchived>({
    method: 'POST',
    url: backendUrl(`/contract_requests/${contractUuid}/archive`),
    withCredentials: true
  })

  return data.contract_request_archived
}

export const postDocument = async (body: DocumentData): Promise<any> => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(body)) {
    formData.append(key, value)
  }
  const { data } = await axios.request<DocumentCreated>({
    method: 'POST',
    url: v2BackendUrl(`/contract_requests/${body.contractRequestUuid}/documents`),
    data: formData,
    withCredentials: true,
    headers: {
      'x-security-token': SECURITY_TOKEN
    }
  })

  return [data]
}

export const deleteDocument = async (body: DeleteDocumentData): Promise<any> => {
  const { data } = await axios.request<DocumentCreated>({
    method: 'DELETE',
    url: v2BackendUrl(`/documents/${body.documentUuid}/files/${body.fileId}`),
    withCredentials: true
  })

  return [data]
}

export const patchDrivingLicence = async (body: DrivingLicenceDocumentData): Promise<any> => {
  const { data } = await axios.request<DocumentCreated>({
    method: 'PATCH',
    url: v2BackendUrl(`/people/${body.personUuid}/driving_licences/${body.uuid}`),
    data: { temporary: body.temporay },
    withCredentials: true
  })
  return data
}

export const patchMotoLicencePlate = async (
  licencePlate: string,
  motorbikeBelongingUuid: string,
  contractUuid: string
): Promise<any> => {
  const { data } = await axios.request<{}>({
    method: 'PATCH',
    url: backendUrl(`/belongings/${motorbikeBelongingUuid}`),
    data: { belonging: { licence_plate: licencePlate, contract_uuid: contractUuid } },
    withCredentials: true
  })
  return data
}

const authenticated = async (): Promise<boolean> => {
  const { data } = await axios.request<Authenticated>({
    method: 'GET',
    url: backendUrl('/authenticated'),
    withCredentials: true
  })
  return data.authenticated
}

//* ERROR HANDLING

const isUnauthorizedError = (error: unknown): boolean => error instanceof AxiosError && error.response?.status === 401

const AxiosErrorApiResponse = AxiosError<{ errors: string[] }>

const errorToMessages = (error: unknown): string[] => {
  // TODO: i18n
  if (error instanceof AxiosErrorApiResponse) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // Extract the error message from the response
      try {
        const { errors } = JSON.parse(error.response.data)
        return errors
      } catch (e) {
        return ['Something wrong happened. Please contact us for assistance.']
      }
    } else if (error.request) {
      // The request was made but no response was received
      return ['No response received']
    } else {
      // Something happened in setting up the request that triggered an error
      return [`Error: ${error.message}`]
    }
  } else {
    // Something happened in setting up the request that triggered an error
    return [`Error: ${(error as Error).message}`]
  }
}

export default {
  authenticated,
  getMe,
  getMeToken,
  getContractRequests,
  getContracts,
  getB2BInsuranceCertificates,
  getTransfers,
  getContract,
  getClaim,
  getClaims,
  getContractRequest,
  getLifeProcedure,
  getDocuments,
  getInsuranceCertificates,
  getDocumentsToSign,
  getPendingDocumentsToSign,
  getPendingDocumentsToUpload,
  getPendingUnpaids,
  changePassword,
  resetPassword,
  postZendeskTicket,
  postDocument,
  deleteDocument,
  isUnauthorizedError,
  errorToMessages,
  visitPage,
  postOnboard,
  getUserProfiles,
  getCompanyContracts,
  getCompanyContract,
  getCompanyClaims,
  getCompanyDetails,
  getCompanyPendingDocumentsToSign,
  getCompanyDocuments
}
