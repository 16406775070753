<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import { useMeStore } from '@/stores/me'
import { brokerIsStroobants } from '@/utils/brokers'
import { RequestStatus } from '@/utils/restStore'
import { ref, watch } from 'vue'
import { onMounted } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'

import OnBoarding from '@/components/home/organisms/OnBoarding.vue'

import ProfileSelect from './components/generic/atoms/ProfileSelect.vue'
import ProfilesModal from './components/generic/organisms/ProfilesModal.vue'
import AppStructure from './components/generic/templates/AppStructure.vue'
import { SubDomain } from './router'
import { profileChangeIsAvailable } from './utils/profile'
import {
  hideZendeskChat,
  identifyCustomerScript,
  mountCustomerIoScript,
  mountZendeskScript,
  setZendeskToken
} from './utils/script_integrations'

const authStore = useAuthStore()
const meStore = useMeStore()
let showOnboardingModal = ref(false)

const closeOnbardingModal = () => {
  showOnboardingModal.value = false
  if (!localStorage.getItem('operatorId')) meStore.postOnboarded()
}

const route = useRoute()
const router = useRouter()

const setToken = async () => {
  await meStore.fetchToken()
  if (meStore.meToken.status === RequestStatus.RequestLoaded && document.getElementById('ze-snippet')) {
    setZendeskToken(meStore.meToken.data.token)
  }
}
watch(
  () => meStore.showOnboarding,
  async (value) => {
    if (!localStorage.getItem('operatorId')) showOnboardingModal.value = value
  }
)
watch(
  () => meStore.isUnfit,
  async (value) => {
    if (value) {
      if (document.getElementById('ze-snippet')) {
        hideZendeskChat()
      }
    }
  }
)
watch(
  () => authStore.isAuthenticated,
  async (value) => {
    if (value) meStore.fetchProfiles()
    if (!brokerIsStroobants()) {
      const zendeskScriptLoaded = await mountZendeskScript()
      if (zendeskScriptLoaded) setToken()
      const customerScriptLoaded = await mountCustomerIoScript()
      if (customerScriptLoaded) {
        const loadCustomerData = async () => {
          if (meStore.me.status !== RequestStatus.RequestLoaded) {
            await meStore.fetchMe().catch(() => {})
          }
          if (meStore.me.status === RequestStatus.RequestLoaded) {
            identifyCustomerScript(meStore.me.data.email, meStore.me.data.createdAt)
          }
        }
        loadCustomerData()
      }
    }
  }
)

function setFavicon(iconUrl: string, title: string) {
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']")
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }
  link.href = iconUrl

  let metaOgImage: HTMLMetaElement | null = document.querySelector("meta[property='og:image']")
  if (!metaOgImage) {
    metaOgImage = document.createElement('meta')
    metaOgImage.setAttribute('property', 'og:image')
    document.head.appendChild(metaOgImage)
  }
  metaOgImage.setAttribute('content', iconUrl)

  let appleTouchIcon: HTMLLinkElement | null = document.querySelector("link[rel='apple-touch-icon']")
  if (!appleTouchIcon) {
    appleTouchIcon = document.createElement('link')
    appleTouchIcon.rel = 'apple-touch-icon'
    document.head.appendChild(appleTouchIcon)
  }
  appleTouchIcon.href = iconUrl

  document.title = title
}

onMounted(async () => {
  if (brokerIsStroobants()) setFavicon('/stroobants-favicon.png', 'My Yago for Stroobants')
  else setFavicon('/favicon.png', 'My Yago')

  // document.querySelectorAll("link[rel~='icon']").forEach((link) => link.remove())
  if (meStore.me.status === RequestStatus.NoRequest) await meStore.fetchMe()
  if (meStore.stroobantsClient)
    router.push({
      name: route.name ? route.name : 'home',
      params: { ...route.params, subdomain: SubDomain.Stroobants }
    })
})
</script>

<template>
  <ProfilesModal
    v-if="meStore.me.status === RequestStatus.RequestLoaded && !meStore.selectedClient && !meStore.selectedCompany" />

  <OnBoarding :close-modal="closeOnbardingModal" v-if="showOnboardingModal" />
  <AppStructure>
    <ProfileSelect class="lg:hidden" v-if="profileChangeIsAvailable(route.name as string)" />
    <RouterView />
  </AppStructure>
</template>
