import type { Company, Me, MeToken, PendingClaim, Profile } from '@/types/me'
import { Broker, MemberRole, ProfileType } from '@/types/me'
import { PendingType } from '@/types/me'
import { brokerIsStroobants } from '@/utils/brokers'
import { MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS } from '@/utils/claims'
import { RequestStatus, handleRequest, initialRequestState } from '@/utils/restStore'
import type { RequestState } from '@/utils/restStore'
import { defineStore } from 'pinia'

import Api from '@/services/api'

interface bookameetingParamsB2b {
  firstName: string
  lastName: string
  email: string
  phone: string
  advisorFirstname: string
  advisorLastname: string
  advisorEmail: string
}
interface State {
  me: RequestState<Me>
  meToken: RequestState<MeToken>
  bookameetingParamsB2b: bookameetingParamsB2b
  profiles: RequestState<Profile[]>
  selectedCompany: string | null
  selectedClient: string | null
  company: RequestState<Company>
}

const initialState = (): State => ({
  me: initialRequestState(),
  meToken: initialRequestState(),
  bookameetingParamsB2b: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    advisorFirstname: '',
    advisorLastname: '',
    advisorEmail: ''
  },
  profiles: initialRequestState(),
  company: initialRequestState(),
  selectedCompany: localStorage.getItem('selectedCompanyProfile') || null,
  selectedClient: localStorage.getItem('selectedClientProfile') || null
})

export const useMeStore = defineStore({
  id: 'me',
  state: initialState,
  actions: {
    async fetchMe() {
      await handleRequest(Api.getMe, (me: RequestState<Me>) => {
        this.me = me
        if (me.status === RequestStatus.RequestLoaded) {
          this.bookameetingParamsB2b.firstName = me.data.firstName
          this.bookameetingParamsB2b.lastName = me.data.lastName
          this.bookameetingParamsB2b.email = me.data.email
          this.bookameetingParamsB2b.phone = me.data.mobilePhone ? me.data.mobilePhone.replace('+', '00') : ''
          this.bookameetingParamsB2b.advisorFirstname = `${me.data.advisor?.firstName}`
          this.bookameetingParamsB2b.advisorLastname = `${me.data.advisor?.lastName}`
          this.bookameetingParamsB2b.advisorEmail = `${me.data.advisor?.email}`
        }
      })
      if (this.me.status === RequestStatus.RequestLoaded && brokerIsStroobants()) {
        this.me.data.pendingActions = [
          ...this.me.data.pendingActions.filter((action) => action.type !== PendingType.Claim),
          ...this.me.data.pendingActions.filter((action) => {
            if (action.type === PendingType.Claim && 'incidentDate' in action) {
              const year = Number(action.incidentDate.split('/')[2])
              return year >= MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS
            }
            return false
          })
        ]
      }
    },
    async fetchToken() {
      await handleRequest(Api.getMeToken, (token: RequestState<MeToken>) => {
        this.meToken = token
        if (token.status === RequestStatus.RequestLoaded) {
          localStorage.setItem('authToken', token.data.token)
        }
      })
    },
    async fetchProfiles() {
      await handleRequest(Api.getUserProfiles, (profiles: RequestState<Profile[]>) => {
        this.profiles = profiles
        if (profiles.status === RequestStatus.RequestLoaded) {
          if (this.selectedClient && !profiles.data.find((profile) => profile.clientId === this.selectedClient)) {
            //* reset selected client profile when logged to new user
            localStorage.removeItem('selectedClientProfile')
            this.selectedClient = null
          }
          if (
            this.selectedCompany &&
            !profiles.data.find((profile) => profile.company?.companyId === this.selectedCompany)
          ) {
            //* reset selected company profile when logged to new user
            localStorage.removeItem('selectedCompanyProfile')
            this.selectedCompany = null
          }
          if (profiles.data.length === 1) {
            localStorage.setItem('selectedClientProfile', profiles.data[0].clientId as string)
            this.selectedClient = profiles.data[0].clientId as string
          } else {
            //* sort profiles

            const b2cProfiles = profiles.data.filter((profile) => profile.profileType === ProfileType.B2C)
            const b2bProfiles = profiles.data
              .filter(
                (
                  profile
                ): profile is Profile & { company: { companyId: string; legalName: string; legalForm: string } } =>
                  profile.profileType === ProfileType.B2B && profile.company !== undefined
              )
              .sort((a, b) => a.company.legalName.localeCompare(b.company.legalName))

            profiles.data = [...b2cProfiles, ...b2bProfiles]

            this.profiles = profiles
          }
        }
      })
    },
    async fetchCompanyDetails(companyId: string) {
      await handleRequest(
        () => Api.getCompanyDetails(companyId),
        (company: RequestState<Company>) => {
          this.company = company
        }
      )
      if (this.company.status === RequestStatus.RequestLoaded && brokerIsStroobants()) {
        this.company.data.pendingActions = [
          ...this.company.data.pendingActions.filter((PendingAction: PendingClaim) => {
            const year = Number(PendingAction.incidentDate.split('/')[2])
            return year >= MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS
          })
        ]
      }
    },
    async changeSelectedProfile(profile: Profile) {
      if (profile.profileType === ProfileType.B2B) {
        //* set selected company profile
        localStorage.setItem('selectedCompanyProfile', profile.company?.companyId as string)
        this.selectedCompany = profile.company?.companyId as string
        //* reset selected client profile
        localStorage.removeItem('selectedClientProfile')
        this.selectedClient = null

        await this.fetchCompanyDetails(this.selectedCompany as string)
      } else if (profile.profileType === ProfileType.B2C) {
        //* set selected client profile
        localStorage.setItem('selectedClientProfile', profile.clientId as string)
        this.selectedClient = profile.clientId as string
        //* reset selected company profile
        localStorage.removeItem('selectedCompanyProfile')
        this.selectedCompany = null
      }
    },
    async postOnboarded() {
      await handleRequest(Api.postOnboard, () => {})
    }
  },
  getters: {
    marshClient: (state: State): boolean => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data.fromBrokerId === Broker.Marsh : false
    },
    stroobantsClient: (state: State): boolean => {
      return state.me.status === RequestStatus.RequestLoaded
        ? !!state.me.data.fromBrokerId?.includes('stroobants')
        : false
    },
    account: (state: State): Me | null => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data : null
    },
    isUnfit(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && state.me.data.unfit
    },
    showOnboarding(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && !state.me.data.onboarded
    },
    selectedCompanyIsClient(state: State): boolean {
      return state.company.status === RequestStatus.RequestLoaded && state.company.data.anyContract
    },
    memberIsSignatory(state: State): boolean {
      if (state.selectedCompany) {
        return (
          state.profiles.status === RequestStatus.RequestLoaded &&
          !!state.profiles.data
            .find((profile) => profile.company?.companyId === state.selectedCompany)
            ?.roles?.includes(MemberRole.Signatory)
        )
      } else return true
    }
  }
})
