import type { Claim } from '@/types/claim'

export const MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS = 2024

export const filterStroobantsClaims = (claims: Claim[]) => {
  return claims.filter((claim) => {
    const year = Number(claim.reportingDate.split('/')[2])
    return year >= MINIMUM_YEAR_FOR_STROOBANTS_CLAIMS
  })
}
